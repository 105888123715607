import React from 'react'
import {
  FaFacebook,
  FaTwitter,
  FaInstagram,
  FaYoutube,
  FaLinkedin,
} from 'react-icons/fa'
import {
  FooterContainer,
  FooterWrap,
  FooterLinksContainer,
  FooterLinkItems,
  FooterLinksWrapper,
  FooterLinkTitle,
  SocialMedia,
  SocialMediaWrap,
  SocialLogo,
  SocialIcons,
  WebsiteRights,
  SocialIconLink,
} from './FooterElements'
import { animateScroll as scroll } from 'react-scroll'

// To link outside of the website, use normal a tags

const Footer = () => {
  // Function from react-scroll
  const toggleHome = () => {
    scroll.scrollToTop()
  }

  return (
    <FooterContainer>
      <FooterWrap>
        <FooterLinksContainer>
          <FooterLinksWrapper>
            <FooterLinkItems>
              <FooterLinkTitle> About us </FooterLinkTitle>
              {/* <FooterLink to="/"> How it works </FooterLink>
              <FooterLink to="/"> Testimonials </FooterLink>
              <FooterLink to="/"> Careers </FooterLink>
              <FooterLink to="/"> Investors </FooterLink>
              <FooterLink to="/"> Terms of Service </FooterLink> */}
            </FooterLinkItems>
            <FooterLinkItems>
              <FooterLinkTitle> Contact us </FooterLinkTitle>
              {/* <FooterLink to="/"> Contact </FooterLink> */}
              {/* <FooterLink to="/"> Support </FooterLink>
              <FooterLink to="/"> Destinations </FooterLink>
              <FooterLink to="/"> Sponsorships </FooterLink> */}
            </FooterLinkItems>
          </FooterLinksWrapper>
          <FooterLinksWrapper>
            {/* <FooterLinkItems>
              <FooterLinkTitle> Videos </FooterLinkTitle>
              <FooterLink to="/"> Submit Video </FooterLink>
              <FooterLink to="/"> Ambassadors </FooterLink>
              <FooterLink to="/"> Agency </FooterLink>
              <FooterLink to="/"> Influencers </FooterLink>
            </FooterLinkItems> */}
            <FooterLinkItems>
              <FooterLinkTitle> Social Media </FooterLinkTitle>
              {/* <FooterLink to="/"> Instagram </FooterLink>
              <FooterLink to="/"> Facebook </FooterLink>
              <FooterLink to="/"> Youtube </FooterLink>
              <FooterLink to="/"> Twitter </FooterLink> */}
            </FooterLinkItems>
          </FooterLinksWrapper>
        </FooterLinksContainer>
        <SocialMedia>
          <SocialMediaWrap>
            <SocialLogo to="/" onClick={toggleHome}>
              BuildYourMVP
            </SocialLogo>
            <WebsiteRights>
              {' '}
              Built with Love in INDIA <br /> mvp © {new Date().getFullYear()}{' '}
              All rights reserved.{' '}
            </WebsiteRights>
            <SocialIcons>
              <SocialIconLink href="/" target="_blank" aria-label="Facebook">
                <FaFacebook />
              </SocialIconLink>
              <SocialIconLink href="/" target="_blank" aria-label="Instagram">
                <FaInstagram />
              </SocialIconLink>
              <SocialIconLink href="/" target="_blank" aria-label="Youtube">
                <FaYoutube />
              </SocialIconLink>
              <SocialIconLink href="/" target="_blank" aria-label="Twitter">
                <FaTwitter />
              </SocialIconLink>
              <SocialIconLink href="/" target="_blank" aria-label="Linkedin">
                <FaLinkedin />
              </SocialIconLink>
            </SocialIcons>
          </SocialMediaWrap>
        </SocialMedia>
      </FooterWrap>
    </FooterContainer>
  )
}

export default Footer
