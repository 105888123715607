import React, { useState } from 'react'
import HeroSection from '../components/HeroSection'
import Navbar from '../components/Navbar'
import Sidebar from '../components/Sidebar'
import InfoSection from '../components/InfoSection'
import {
  homeObjOne,
  homeObjTwo,
  homeObjThree,
} from '../components/InfoSection/Data'
import Services from '../components/Services'
import Footer from '../components/Footer'
import NetlifyForm from '../components/InfoSection/NetlifyForm'

const Home = () => {
  const [isOpen, setIsOpen] = useState(false)

  const toggle = () => {
    let abortController = new AbortController()
    setIsOpen(!isOpen)
    return () => {
      abortController.abort()
    }
  }

  return (
    <>
      <Sidebar isOpen={isOpen} toggle={toggle} />
      <Navbar toggle={toggle} />
      <HeroSection />
      <InfoSection {...homeObjOne} />
      <InfoSection {...homeObjTwo} />
      <Services />
      <NetlifyForm />
      <InfoSection {...homeObjThree} />
      {/* <InfoSection {...homeObjFour} /> */}

      <Footer />
    </>
  )
}

export default Home
