import { useState } from 'react'
import Modal from 'react-modal'
import './Modal.css'

export default function NetlifyForm() {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const openModal = () => {
    setIsModalOpen(true)
  }

  const closeModal = () => {
    setIsModalOpen(false)
  }

  //const history = useHistory()
  const handleSubmit = (event) => {
    event.preventDefault()

    const myForm = event.target
    const formData = new FormData(myForm)

    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: new URLSearchParams(formData).toString(),
    })
      .then(() => {
        // alert('Thank you for the Details')

        openModal()
      })
      .catch((error) => alert(error))
  }

  return (
    <>
      <div style={{ backgroundColor: 'black' }}>
        <h1
          id="form"
          style={{ textAlign: 'center', color: 'white', paddingTop: '250px' }}
        >
          {' '}
          Allow us to reach you ....{' '}
        </h1>
        {/* <input type="hidden" name="form-name" value="contact"> */}
        <form
          onSubmit={handleSubmit}
          netlify="true"
          method="POST"
          data-netlify="true"
          // netlify-honeypot="bot-field"
          name="myForm"
          style={{
            maxWidth: '600px',
            margin: '0 auto',
            padding: '20px',
            backgroundColor: 'black',
            borderRadius: '5px',
            marginTop: '100px',
            marginBottom: '10px',
            color: 'black',
            height: '700px',
            alignContent: 'center',
          }}
        >
          <div style={{ marginBottom: '20px' }}>
            <label htmlFor="name">Name:</label>
            <input
              type="text"
              id="name"
              name="name"
              style={{
                width: '100%',
                padding: '10px',
                border: '1px solid #ccc',
                borderRadius: '4px',
                height: '60px',
              }}
              required
              placeholder="Enter your name"
            />
          </div>
          <div style={{ marginBottom: '20px' }}>
            <label htmlFor="email">Email:</label>
            <input
              type="email"
              id="email"
              name="email"
              style={{
                width: '100%',
                padding: '10px',
                border: '1px solid #ccc',
                borderRadius: '4px',
                height: '60px',
              }}
              required
              placeholder="Enter your Email ID"
            />
          </div>
          <div style={{ marginBottom: '20px' }}>
            <label htmlFor="name">Occupation:</label>
            <input
              type="text"
              id="name"
              name="name"
              style={{
                width: '100%',
                padding: '10px',
                border: '1px solid #ccc',
                borderRadius: '4px',
                height: '60px',
              }}
              placeholder="Your Occupation"
            />
          </div>
          <div style={{ marginBottom: '20px' }}>
            <label htmlFor="name">Phone:</label>
            <input
              type="number"
              id="name"
              name="name"
              style={{
                width: '100%',
                padding: '10px',
                border: '1px solid #ccc',
                borderRadius: '4px',
                height: '60px',
              }}
              placeholder="Enter your Phone Number"
            />
          </div>
          <div style={{ marginBottom: '20px' }}>
            <label htmlFor="message">Message:</label>
            <textarea
              id="message"
              name="message"
              style={{
                width: '100%',
                padding: '10px',
                border: '1px solid #ccc',
                borderRadius: '4px',
                height: '60px',
              }}
              placeholder="Message"
            ></textarea>
          </div>
          <button
            type="submit"
            style={{
              backgroundColor: '#4caf50',
              color: 'white',
              padding: '10px 20px',
              border: 'none',
              borderRadius: '4px',
              cursor: 'pointer',
              marginLeft: '200px',
              height: '60px',
              width: '140px',
            }}
          >
            Submit
          </button>
        </form>
        <Modal
          isOpen={isModalOpen}
          onRequestClose={closeModal}
          className="modal" // Add the CSS class name for the modal
          overlayClassName="modal-overlay" // Add an overlay class if needed
        >
          <h2>Success!</h2>
          <p>Your form has been submitted successfully.</p>
          <button onClick={closeModal}>Close</button>
        </Modal>
      </div>
    </>
  )
}
// export default function NetlifyForm() {
//   return (
//     <form name="contact" method="POST" data-netlify="true">
//       <p>
//         <label>
//           Your Name: <input type="text" name="name" />
//         </label>
//       </p>
//       <p>
//         <label>
//           Your Email: <input type="email" name="email" />
//         </label>
//       </p>

//       <p>
//         <label>
//           Message: <textarea name="message"></textarea>
//         </label>
//       </p>
//       <p>
//         <button type="submit">Send</button>
//       </p>
//     </form>
//   )
// }
